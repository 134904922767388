import { SELLSTATE, ADJUSTMENT, TIMESPAN } from 'helpers/Const';

const utils = {
  timeoptions: [
    { key: 'EARLY', value: TIMESPAN.EARLY, text: TIMESPAN.EARLY },
    { key: 'NORUSH', value: TIMESPAN.NORUSH, text: TIMESPAN.NORUSH },
    { key: 'LATE', value: TIMESPAN.LATE, text: TIMESPAN.LATE },
    { key: 'DELAYED', value: TIMESPAN.DELAYED, text: TIMESPAN.DELAYED }
  ],
  calibrations: [
    { key: 'CALIBRATED', value: ADJUSTMENT.CALIBRATED, text: ADJUSTMENT.CALIBRATED },
    { key: 'UNCALIBRATED', value: ADJUSTMENT.UNCALIBRATED, text: ADJUSTMENT.UNCALIBRATED },
    { key: 'INAPPLICABLE', value: ADJUSTMENT.INAPPLICABLE, text: ADJUSTMENT.INAPPLICABLE },
    { key: 'UNKNOWN', value: ADJUSTMENT.UNKNOWN, text: ADJUSTMENT.UNKNOWN }
  ],
  sellstates: [
    { key: 'SELLING', value: SELLSTATE.SELLING, text: SELLSTATE.SELLING },
    { key: 'SUSPENDED', value: SELLSTATE.SUSPENDED, text: SELLSTATE.SUSPENDED },
    { key: 'NEGOTIATE', value: SELLSTATE.NEGOTIATE, text: SELLSTATE.NEGOTIATE },
    { key: 'PROCESSING', value: SELLSTATE.PROCESSING, text: SELLSTATE.PROCESSING },
    { key: 'COMPLETED', value: SELLSTATE.COMPLETED, text: SELLSTATE.COMPLETED }
  ]
};

export default utils;
