import React from 'react';
import _ from 'lodash';
import Comment from './Comment';
import PropTypes from 'prop-types';

const commentList = props => {
  const comments = _.get(props, 'data');
  return comments.map(comment => (
    <Comment
      key={_.get(comment, 'objectId')}
      author={_.get(comment, 'owner.representative')}
      updatedDate={_.get(comment, 'updatedAt')}
      content={_.get(comment, 'content')}
    />
  ));
};

commentList.propTypes = {
  classes: PropTypes.object
};

export default commentList;
