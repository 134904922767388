import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { sanitizeListRestProps } from 'ra-core';

import FilterForm from './FilterForm';

const styles = {
  button: {},
  form: {}
};

export class Filter extends Component {
  renderButton() {
    return <div />;
  }

  renderForm() {
    const {
      classes = {},
      context,
      resource,
      children,
      hideFilter,
      displayedFilters,
      showFilter,
      filterValues,
      setFilters,
      ...rest
    } = this.props;

    return (
      <FilterForm
        className={classes.form}
        resource={resource}
        filters={React.Children.toArray(children)}
        hideFilter={hideFilter}
        displayedFilters={displayedFilters}
        initialValues={filterValues}
        setFilters={setFilters}
        {...sanitizeListRestProps(rest)}
      />
    );
  }

  render() {
    return this.props.context === 'button' ? this.renderButton() : this.renderForm();
  }
}

Filter.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  context: PropTypes.oneOf(['form', 'button']),
  displayedFilters: PropTypes.object,
  filterValues: PropTypes.object,
  hideFilter: PropTypes.func,
  setFilters: PropTypes.func,
  showFilter: PropTypes.func,
  resource: PropTypes.string.isRequired
};

export default withStyles(styles)(Filter);
